<div
  *transloco="let t"
  class="flex min-h-screen w-screen max-w-full justify-center bg-white"
>
  <div class="w-screen md:m-5">
    <h1
      class="font-normal text-title-large sm:text-headline-small text-on-surface-variant mb-3"
    >
      {{ t('appTitle') }}
    </h1>
    <mat-divider class="mb-3 sm:mb-9"></mat-divider>
    <schaeffler-banner></schaeffler-banner>
    <lsa-recommendation-container></lsa-recommendation-container>
    <div class="w-full min-w-full flex flex-row justify-center">
      <schaeffler-feedback-banner
        class="w-full max-w-2xl"
        [surveyUrl]="t('feedback.surveyUrl')"
        [infoText]="t('feedback.text')"
        [providedLanguages]="['de', 'en']"
        [feedbackButtonText]="t('feedback.button')"
      />
    </div>
  </div>
</div>
