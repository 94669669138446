<div
  class="absolute top-0 left-0 bottom-0 right-0 px-2"
  [ngClass]="{ 'pr-0': isLastColumn }"
>
  <div
    class="p-2 h-full text-body-medium text-medium-emphasis flex flex-row items-center justify-start"
    [ngClass]="{
      'border-none md:border-solid border-x border-border': border,
      'border-primary': selected,
      'border-b rounded-b-lg': isLastRow
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
