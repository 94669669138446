<ng-container *transloco="let t">
  <lsa-stepper (selectionChange)="selectionChanged($event)">
    <ng-container *ngrxLet="selectedStep$ as currentStep">
      @for (page of pages; track page; let index = $index) {
        <cdk-step
          lsaStep
          matStepContent
          [label]="t('pages.' + page.name + '.title')"
          [completed]="page.completed"
        >
          <div class="md:px-8 px-2 pt-8">
            @switch (index) {
              @case (0) {
                <lsa-lubrication-points
                  [lubricationPointsForm]="lubricationPointsForm"
                ></lsa-lubrication-points>
              }
              @case (1) {
                <lsa-lubricant
                  [lubricantForm]="lubricantForm"
                  [greases]="greases$ | ngrxPush"
                ></lsa-lubricant>
              }
              @case (2) {
                @if (index === currentStep) {
                  <lsa-application
                    [applicationForm]="applicationForm"
                  ></lsa-application>
                }
              }
              @case (3) {
                <ng-container *ngrxLet="recommendation$ as recommendation">
                  <lsa-result [recommendationResult]="recommendation">
                    <lsa-lubrication-inputs
                      [inputs]="resultInputs"
                      (navigateToStep)="navigateToStep($event)"
                    ></lsa-lubrication-inputs>
                  </lsa-result>
                </ng-container>
                <div
                  class="hidden md:flex flex-col gap-10 justify-around max-w-full"
                >
                  <div class="flex flex-col">
                    <span>Greases:</span>
                    <pre>{{ greases$ | ngrxPush | json }}</pre>
                  </div>
                  <div class="flex flex-col">
                    <span>Form:</span>
                    <pre>{{ form.getRawValue() | json }}</pre>
                    <span>Valid: {{ form.valid }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span>Result:</span>
                    <pre>{{ recommendation$ | ngrxPush | json }}</pre>
                  </div>
                </div>
              }
            }
          </div>
        </cdk-step>
      }
    </ng-container>
  </lsa-stepper>
</ng-container>
<!-- <pre>{{ form.value | json }}</pre> -->
